import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");
  const [menuOpen, setMenuOpen] = useState(false);

  // const toggleTheme = () => {
  //   setTheme(prevTheme => (prevTheme === "light" ? "dark" : "light"));
  // };

  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === "light" ? "dark" : "light";
      updateBodyClasses(newTheme, menuOpen);
      return newTheme;
    });
};

const toggleMenu = () => {
  setMenuOpen(prevState => {
    const newMenuState = !prevState;
    updateBodyClasses(theme, newMenuState);
    return newMenuState;
  });
};

  const updateBodyClasses = (currentTheme, isMenuOpen) => {
    const classes = [currentTheme];
    if (isMenuOpen) {
      classes.push('menu-open');
    }
    document.body.className = classes.join(' ');
  };

  return (
    <GlobalContext.Provider value={{ theme, toggleTheme, menuOpen, toggleMenu }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);

